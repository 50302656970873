import get from 'lodash.get'
import { useRouter } from 'next/router'
import type { ReactNode } from 'react'
import React, { createContext, useCallback, useState } from 'react'

import type { Settings, TItemMethod, User, UserV2 } from '~/apis'
import {
  getMFAMethodsAPI,
  getProfileAPI,
  getProfileV2API,
  getSettingAPI,
  logoutAPI,
  updateProfileV2API,
  updateSecondaryV2API,
} from '~/apis'
import { removeAuthCookies, removeSessionId } from '~/core/cookie'
import { useSentry } from '~/core/sentry'
import { unflattenObject } from '~/utils/helper'

import type { ContextState } from './types'

const defaultState: ContextState = {
  user: null,
  userV2: null,
  settings: null,
  mfaMethods: null,
  isFetched: false,
  getProfile: () => {},
  updateContextProfile: () => {},
  getSettings: () => {},
  getMFAMethods: () => {},
  resetContext: () => {},
  logout: () => {},
}

export const AuthContext = createContext<ContextState>(defaultState)

export const AuthProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [user, setUser] = useState<User | null>(null)
  const [userV2, setUserV2] = useState<UserV2 | null>(null)
  const [settings, setSettings] = useState<Settings | null>(null)
  const [mfaMethods, setMfaMethods] = useState<TItemMethod[] | null>([])
  const [isFetched, setIsFetched] = useState<boolean>(false)
  const router = useRouter()
  const { captureException } = useSentry()

  function updateContextProfile(updated: Partial<User>) {
    setUser(pre => ({ ...pre, ...updated } as User))
  }

  const resetContext = () => {
    setUser(null)
    setSettings(null)
    setIsFetched(false)
  }

  const getProfile = useCallback(async () => {
    try {
      const [user, rawV2] = await Promise.all([
        getProfileAPI(),
        getProfileV2API(),
      ])

      const hasBackup = get(rawV2, 'account.wallet.identity.has_backup')
      delete rawV2['account.wallet.identity.has_backup']

      const userV2 = unflattenObject<UserV2>(rawV2)

      // Will remove after BE fix
      const secondaryV1 = user.socialAccounts?.find(
        item => item.authType === 'ronin',
      )?.socialID

      if (userV2.account.wallet) {
        const secondaryV2 = userV2.account.wallet.secondary

        if (secondaryV1 && !secondaryV2) {
          await updateSecondaryV2API(secondaryV1)
        }

        userV2.account.wallet.secondary = secondaryV2 || secondaryV1 || ''
      }
      //

      if (hasBackup === 'false') {
        userV2.keylessFailed = true
      }

      if (
        userV2.account.wallet?.secondary &&
        !userV2.account?.wallet?.default
      ) {
        await updateProfileV2API('wallet.secondary')
      }

      setUser(user)
      setUserV2(userV2)
    } catch (error) {
      setUser(null)
      setUserV2(null)
    } finally {
      setIsFetched(true)
    }
  }, [])

  const getSettings = useCallback(async () => {
    try {
      const configs = await getSettingAPI()
      setSettings(configs)
    } catch (error) {
      setSettings(null)
    }
  }, [])

  const getMFAMethods = useCallback(async () => {
    try {
      const configs = await getMFAMethodsAPI()
      setMfaMethods(configs)
    } catch (error) {
      setMfaMethods([])
    }
  }, [])

  const logout = async () => {
    setIsFetched(false)

    try {
      const { data } = await logoutAPI()
      removeAuthCookies()
      removeSessionId()

      if (data.redirect_to) router.replace(data.redirect_to)
      else {
        window.location.replace('/')
      }
    } catch (error) {
      removeAuthCookies()
      removeSessionId()
      captureException(error)
    }
  }

  return (
    <AuthContext.Provider
      value={{
        user,
        userV2,
        settings,
        mfaMethods,
        isFetched,
        resetContext,
        getProfile,
        updateContextProfile,
        getSettings,
        getMFAMethods,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
